import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
// import { withRouter } from "react-router-dom";
import StoreDetails from '../components/StoreDetails';
import ProductList from '../components/ProductList/ProductList';
import ReviewsSection from '../features/Reviews/ReviewsSection';
import LoadingError from '../components/LoadingError';
import { makeStyles } from '@material-ui/styles';
import { convertToDate } from '../utils/helpers';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import SkeletonSimpleCard from '../components/SkeletonSimpleCard';
import SimpleCard from '../components/SimpleCard';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '../components/TwitterIcon';
import { Helmet } from 'react-helmet';
import api from '../utils/api';
import {
  getCurrentWeekDay,
  parseHoursObject,
  parseIdFromLink,
  openStatus,
  formatPhoneNumber,
  createOpenHoursString,
  // abbreviate,
} from '../utils/helpers';

import { NAV_OPTIONS, FEATURED_TYPES, STATUS, EMPTY_STORE_HOURS } from '../utils/constants';

import './styles/StoreDetailsView.css';
import DescriptionBox from '../components/DescriptionBox';
import PreviewContext from '../PreviewContext';
// import StaticMap from "../components/StaticMap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '1em',
    maxWidth: 1200,
    color: '#000000',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  tabRoot: {},
  indicator: {
    position: 'absolute',
    height: 4,
    top: 0,
    transition: 'none',
  },
  details: {},
  productList: {},
  midTabBar: {
    zIndex: 98,
    boxShadow: '0px 0px',
    backgroundColor: 'transparent',
  },
  storeTab: {
    /* TODO - DRY across all detail screens */
    minWidth: 40,
    color: theme.palette.common.black,
    backgroundColor: '#ebebeb',
    // margin: theme.spacing(0, 1, 0, 0),
    border: '1px solid #e0e0e0',
    fontWeight: '700',
    fontSize: '14pt',
    zIndex: 90,
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  storeTabSelected: {
    minWidth: 40,
    color: theme.palette.secondary.main,
    fontWeight: '700',
    // margin: theme.spacing(0, 1, 0, 0),
    zIndex: 92,
    border: '1px solid #e0e0e0',
    borderTop: '0px',
    borderBottom: '1px solid #ffffff',
    fontSize: '14pt',
    // paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  panelContainer: {
    border: '1px solid #e0e0e0',
    zIndex: 91,
    padding: theme.spacing(2),
    marginTop: -1,
    // marginRight: theme.spacing(1),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  detailsTab: {
    display: 'flex',
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: theme.spacing(1),
    },
  },
  detailsText: {
    width: '60%',
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  detailsCard: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dealGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(10),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto auto',
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
    },
  },
  detailsTable: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  detailsTableCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: 8,
    wordWrap: 'anywhere',
  },
  hoursTableCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: 2,
  },
  boldCell: {
    fontWeight: 'bold',
  },
  detailedPara: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  body: {
    wordBreak: 'break-all',
  },
  readMore: {
    margin: theme.spacing(1, 0, 1, 0),
    alignSelf: 'flex-end',
  },
  dealsTab: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
  },
  reviewsTab: {
    margin: theme.spacing(2),
  },
  reviewsFlexBox: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  reviews: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  leaveReview: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  reviewForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  reviewInput: {
    marginTop: theme.spacing(4),
  },
  internetMedia: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  mediaIcon: {
    color: theme.palette.grey.A700,
    marginRight: theme.spacing(1),
  },
}));

const getStore = async (id) => {
  return api
    .getStoreDetailedProjection(id)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getProducts = async (id, fetchSize, page, cancelCallback) => {
  return (
    api
      // .getProductsForStoreCustomized(id)  //Former API included unpublished products
      .searchProductsForStore(id, 'customized', true, fetchSize, page, 'name', cancelCallback)
      .then((res) => {
        return res; // ao: changed api response for sdaw-1088, added ._embedded.products here.
      })
      .catch((err) => {
        throw err;
      })
  );
};

const getDeals = async (id) => {
  return api
    .getDealsForStore(id)
    .then((res) => res.filter((deal) => new Date() < convertToDate(deal.endDate)))
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function DetailsTab(props) {
  const { store /*, mobile*/ } = props;

  const classes = useStyles();

  const parsedHours = parseHoursObject(store.hours);

  const currentWeekDay = getCurrentWeekDay();

  return (
    <div className={classes.detailsTab}>
      <div className={classes.detailsText}>
        <Typography variant="h5" className={classes.title}>
          About Us
        </Typography>
        <DescriptionBox text={store.aboutUs}></DescriptionBox>
        {store.firstCustomerSpecial && (
          <div className={classes.detailedPara}>
            <Typography variant="h5" className={classes.title}>
              How to Order
            </Typography>
            <DescriptionBox text={store.firstCustomerSpecial}></DescriptionBox>
          </div>
        )}
        {store.announcement && (
          <div className={classes.detailedPara}>
            <Typography variant="h5" className={classes.title}>
              Special Announcment
            </Typography>
            <DescriptionBox text={store.announcement}></DescriptionBox>
          </div>
        )}
        {store.minimumAge && (
          <Fragment>
            <Typography variant="h6" className={classes.title}>
              Minimum Age
            </Typography>
            <Typography variant="body2" className={classes.body}>
              {store.minimumAge}
            </Typography>
          </Fragment>
        )}
        <Typography variant="h6" className={classes.title}>
          Accepted Payment Methods
        </Typography>
        <Typography variant="body2" className={classes.body}>
          {store.paymentOptions.join(', ')}
        </Typography>
      </div>
      <div className={classes.detailsCard}>
        <TableContainer component={Paper}>
          <Table className={classes.detailsTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <LocationOnIcon></LocationOnIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  {`${store.address.street ? store.address.street + ', ' : ''}${store.address.city}, ${
                    store.address.province
                  }${store.address.postalCode ? ', ' + store.address.postalCode : ''}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <PhoneIcon></PhoneIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>{formatPhoneNumber(store.phone)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <QueryBuilderIcon></QueryBuilderIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>
                  <Table>
                    <TableBody>
                      {parsedHours.map((day) => {
                        let boldClass = '';

                        if (day.day === currentWeekDay) {
                          boldClass = boldClass + `${classes.boldCell}`;
                        }

                        return (
                          <TableRow key={day.day}>
                            <TableCell className={`${classes.hoursTableCell} ${boldClass}`}>{day.day}</TableCell>
                            <TableCell className={`${classes.hoursTableCell} ${boldClass}`}>
                              {createOpenHoursString(day)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.detailsTableCell} align="center">
                  <EmailIcon></EmailIcon>
                </TableCell>
                <TableCell className={classes.detailsTableCell}>{store.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={classes.internetMedia}>
            {store.website && (
              <IconButton className={classes.mediaIcon} href={'//' + store.website} target="_blank">
                <LanguageIcon />
              </IconButton>
            )}
            {store.facebook && (
              <IconButton className={classes.mediaIcon} href={'//' + store.facebook} target="_blank">
                <FacebookIcon />
              </IconButton>
            )}
            {store.instagram && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.instagram[0] === '@'
                    ? `https://www.instagram.com/${store.instagram.slice(1, store.instagram.length)}`
                    : '//' + store.instagram
                }
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
            )}
            {store.snapchat && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.snapchat[0] === '@'
                    ? `https://www.snapchat.com/add/${store.snapchat.slice(1, store.snapchat.length)}`
                    : '//' + store.snapchat
                }
                target="_blank"
              >
                {/* <CameraEnhanceIcon /> */}
                <img src={require(`../assets/logos/iconSnapchat.svg`)} alt={`snapchat-main-logo`} height="30" />
              </IconButton>
            )}
            {store.twitter && (
              <IconButton
                className={classes.mediaIcon}
                href={
                  store.twitter[0] === '@'
                    ? `https://x.com/${store.twitter.slice(1, store.twitter.length)}`
                    : '//' + store.twitter
                }
                target="_blank"
              >
                <TwitterIcon />
              </IconButton>
            )}
          </div>
        </TableContainer>
      </div>
    </div>
  );
}

function DealsTab(props) {
  const { /*deals*/ storeId, province, city } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [deals, setDeals] = useState({});

  useEffect(() => {
    getDeals(storeId)
      .then((res) => {
        setDeals(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log('get deals error: ', err);
        setLoadingError(true);
      });
  }, [storeId]);

  return (
    <div className={classes.dealsTab}>
      <Typography variant="h4" className={classes.title}>
        Deals
      </Typography>
      {loadingError && <Typography>There was an issue while fetching store deals</Typography>}
      <div className={classes.dealGrid}>
        {loading && (
          <Fragment>
            <SkeletonSimpleCard></SkeletonSimpleCard>
            <SkeletonSimpleCard></SkeletonSimpleCard>
            <SkeletonSimpleCard></SkeletonSimpleCard>
          </Fragment>
        )}
        {!loading &&
          deals.length > 0 &&
          deals.map((deal) => {
            return (
              <SimpleCard
                item={deal}
                itemPath={`${
                  NAV_OPTIONS.find((option) => option.name === 'Deals').path
                }/${province}/${city}/${parseIdFromLink(deal._links.self.href)}/${deal.slug}`}
                type={FEATURED_TYPES.DEAL}
                key={parseIdFromLink(deal._links.self.href)}
              ></SimpleCard>
            );
          })}
        {!loading && deals.length === 0 && <Typography>There are no deals for this store at this time.</Typography>}
      </div>
    </div>
  );
}

function StoreDetailsView(props) {
  const { /*productList, reviews,*/ history, previewMode } = props;

  const selectedCategory = {};
  const selectedBrands = [];

  const preview = useContext(PreviewContext);

  const mobile = useMediaQuery('(max-width:425px)');

  if (props.location.query) {
    selectedCategory.category = props.location.query.category;
    selectedCategory.parentCategory = props.location.query.parentCategory;
  } else if (preview.state.query.category) {
    selectedCategory.category = preview.state.query.category;
    selectedCategory.parentCategory = preview.state.query.parentCategory;
  }

  if (props.location.query && props.location.query.brand) {
    selectedBrands.push(props.location.query.brand);
  }

  const storeData = useRef({});

  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const [productList, setProductList] = useState([]);

  const [productLoading, setProductLoading] = useState(true);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);

  const [productLoadingError, setProductLoadingError] = useState(false);
  const [open, setOpen] = useState(false);

  const storeId = !preview.state.screen ? props.match.params.id : preview.state.storeId;

  const [storeSlug, setSlug] = useState(props.match.params.storeSlug);
  const province = props.match.params.province;
  const city = props.match.params.city;

  useEffect(() => {
    let mounted = true;
    let cancelRequest;
    const assignCancelRequest = (input) => {
      cancelRequest = input;
    };

    const buildProductsList = (input) => {
      return input.filter((product) =>
        product.productCustomizations.length > 0 &&
        product.productCustomizations.some(
          (custom) => parseIdFromLink(custom.store._links.self.href, 0, '{?projection}') === storeId
        )
          ? !product.productCustomizations.find(
              (custom) => parseIdFromLink(custom.store._links.self.href, 0, '{?projection}') === storeId
            ).removeFromMenu
          : !product.removeFromMenu
      );
    };
    const getAndSetStore = async () => {
      try {
        const res = await getStore(storeId);
        const pinAddresses = await api.fetchHref(res._links.pinAddresses.href, 'pinAddresses');

        if (!storeSlug) setSlug(res.slug); //Ref 1381, will update the slug if the URL doesn't contain the store name []TODO find where the name should be assigned
        //if the store is not published we redirect to either /deliveries or /stores depending on the storetype
        if (res.published || previewMode) {
          if (res.hours === null) {
            res.hours = EMPTY_STORE_HOURS;
          }
          //Find and update with local address if available
          const currentRegion = {
            city: window.location.href.split('/')[5],
            province: window.location.href.split('/')[4],
          };
          const matchingAddress = pinAddresses.find((pin) => {
            return (
              pin.address.city.toLowerCase() === currentRegion.city.toLowerCase() &&
              pin.address.province.toLowerCase() === currentRegion.province.toLowerCase()
            );
          });

          res.address = matchingAddress
            ? {
                ...matchingAddress.address,
                localCoordinates: { long: matchingAddress.longitude, lat: matchingAddress.latitude },
              }
            : res.address;

          storeData.current = res;
          if (!previewMode) {
            // ao: sdaw-1162 - redirect to the correct province name
            const urlProv = window.location.href.split('/')[4] && window.location.href.split('/')[4].toLowerCase();
            const urlMailouts = window.location.href.split('/')[3] && window.location.href.split('/')[3];
            if (
              urlMailouts !== 'mailouts' &&
              urlProv !== storeData.current.address.province.replace(/ /gi, '-').toLowerCase()
            ) {
              const urlDetails = `${storeData.current.address.province.replace(/ /gi, '-').toLowerCase()}/${city
                .replace(/ /gi, '-')
                .toLowerCase()}/${storeId}/${res.slug}`;
              storeData.current.storeType === 'Delivery'
                ? (window.location.href = `/deliveries/${urlDetails}`)
                : (window.location.href = `/stores/${urlDetails}`);
            }
          }
          setLoading(false);

          if (mounted) {
            setOpen(openStatus(res.hours));
            setLoading(false);
          }
        } else {
          res.storeType === 'Delivery' ? window.location.assign('/deliveries') : window.location.assign('/stores');
        }
      } catch (err) {
        //SDAW1001Temp 404 fix for stores that are deleted
        window.location.assign('/');
        console.log('get store error:', err);
        setLoadingError(true);
      }
    };
    getAndSetStore();

    const querySize = 50;
    getProducts(storeId, querySize, 0, assignCancelRequest)
      .then((res) => {
        // const totalElements = res.page.totalElements;
        if (!res) return; //[]TODO: !res is caused by axios cancel token, need to put in proper cancel handling.
        const totalPages = res.page.totalPages || 1;

        //DL OTHER PAGES
        const queryRemainder = [];
        for (let page = 1; page < totalPages; page++) {
          queryRemainder.push({ storeId, querySize, page, callback: assignCancelRequest });
        }
        Promise.all(queryRemainder.map((q) => getProducts(q.storeId, q.querySize, q.page, q.callback))).then(
          (remData) => {
            const allData = {};
            const addProductToAllData = (input) => {
              for (let product of input) {
                const currID = parseIdFromLink(product._links.self.href);
                allData[currID] = product;
              }
            };
            remData.forEach((pageOfData) => {
              addProductToAllData(pageOfData._embedded.products);
            });
            //Add initial Page
            addProductToAllData(res._embedded.products);
            if (mounted) {
              setProductList(buildProductsList(Object.values(allData)));
              setAllProductsLoaded(true);
            }
          }
        );
        //Set first Page when available
        if (mounted) {
          setProductList(buildProductsList(res._embedded.products));
          setProductLoading(false);
        }
      })
      .catch((err) => {
        console.log('get product err: ', err);
        setProductLoadingError(true);
      });

    return () => {
      mounted = false;
      if (cancelRequest) cancelRequest();
    };
  }, [storeId, previewMode, city, storeSlug]);

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [focusReviewForm, setFocusReviewForm] = useState(false);

  const jumpToReview = () => {
    setValue(3);
    // setFocusReviewForm(true);
  };
  const isMailout = props.match.url.includes('mailouts');
  const currCity = props?.match?.params?.city?.replace(/^\w/, (c) => c.toUpperCase()) || '';

  const determineMetaData = () => {
    const mailoutMeta = {
      title: `${storeData?.current?.name || ''} - Canadian Mail Order Weed`,
      description: `Find ${storeData?.current?.name} marijuana deals for mail order. Browse the latest weed products and best deals today and order online!`,
      h1: `${storeData?.current?.name} - Marijuana Products & Delivery`,
    };

    const deliveryMeta = {
      title: `${storeData?.current?.name || ''}, ${currCity} - Weed Delivery Online`,
      description: `Find ${storeData?.current?.name} marijuana deals in ${currCity} for delivery. Browse the latest weed products and best deals today and order online!`,
      h1: `${storeData?.current?.name} - Marijuana Products & Delivery`,
    };

    const storeMeta = {
      title: `${storeData?.current?.name || ''}, ${currCity} - Weed Dispensary`,
      description: `Discover exclusive ${storeData?.current?.name} weed deals in ${currCity}, with exclusive deals and savings on S7DAW. Find your next favourite product today!`,
      h1: `${storeData?.current?.name || ''} - Weed Dispensary`,
    };

    if (isMailout) return mailoutMeta;
    return storeData.current.storeType === 'Delivery' ? deliveryMeta : storeMeta;
  };
  let pageMetaData = determineMetaData();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{pageMetaData.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={pageMetaData.description} />

        {storeId === '10092' && (
          <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "Service",
              name: "Kush In Motion",
              serviceType: "Weed Delivery",
              providerMobility: "dynamic",
              alternateName: "S7DAW",
              image: "https://www.s7daw.com/static/media/logoHeader.e4d6df00.svg",
              url: "https://s7daw.com/"
            }
          `}</script>
        )}
        {storeId === '10092' && (
          <script type="application/ld+json">{`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              name: "Kush In Motion",
              description:
                "Kush In Motion only provides you with the highest quality premium flower. ALL hand selected so we never disappoint. All our flower is ONLY West Coast Grown. We promise to continue to keep you happy with your favourite exotics, pinks and that sticky icky. We are happy to provide Flavours for the Elite. No delivery fee; minimum order amounts do apply to all locations. Minimum orders vary depending on your exact location, minimum orders are based on km distance.",
              image: {
                "@type": "ImageObject",
                url: "https://www.s7daw.com/static/media/logoHeader.e4d6df00.svg",
                height: 600,
                width: 900,
              },
              priceRange: "$$",
              telephone: "(647) 503-6655",
              address: "104 Goodwood park court, Toronto ON M4C 2G9, Canada",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "5",
                bestRating: "5",
                worstRating: "5",
                ratingCount: "33",
              }
            }
          `}</script>
        )}
      </Helmet>
      <Typography variant="h6" component="h1" className={classes.pageTitle}>
        {pageMetaData.h1}
      </Typography>
      {loading && !loadingError && (
        <Typography style={{ marginTop: '20px', alignSelf: 'center' }}>Loading...</Typography>
      )}
      {loadingError && <LoadingError onClick={() => history.push('/storefronts')} browse="Stores"></LoadingError>}
      {!loading && !loadingError && (
        <Fragment>
          <StoreDetails
            className={classes.details}
            storeDetails={storeData.current}
            open={open}
            loading={loading}
            jumpToReview={() => jumpToReview()}
            mobile={mobile}
          />
          <AppBar position="static" className={classes.midTabBar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="tabs"
              variant="fullWidth"
              classes={{ indicator: classes.indicator, root: classes.tabRoot }}
            >
              <Tab
                label="Menu"
                id={`tab-menu`}
                aria-controls={`tabpanel-menu`}
                className={value === 0 ? classes.storeTabSelected : classes.storeTab}
              />
              <Tab
                label="Details"
                id={`tab-details`}
                aria-controls={`tabpanel-details`}
                className={value === 1 ? classes.storeTabSelected : classes.storeTab}
              />
              <Tab
                label="Deals"
                id={`tab-deals`}
                aria-controls={`tabpanel-deals`}
                className={value === 2 ? classes.storeTabSelected : classes.storeTab}
              />
              <Tab
                label="Reviews"
                id={`tab-deals`}
                aria-controls={`tabpanel-deals`}
                className={value === 3 ? classes.storeTabSelected : classes.storeTab}
              />
            </Tabs>
          </AppBar>
          <div className={classes.panelContainer}>
            {value === 0 && productLoading && !productLoadingError && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ marginTop: '20px' }}>Loading...</Typography>
              </div>
            )}
            {value === 0 && productLoadingError && (
              <LoadingError onClick={() => history.push('/')} browse="Stores"></LoadingError>
            )}
            {value === 0 && !productLoading && !productLoadingError && (
              <ProductList
                className={classes.productList}
                productList={productList}
                selectedCategory={selectedCategory}
                selectedBrands={selectedBrands}
                storeId={storeId}
                storeSlug={storeSlug}
                province={province}
                city={city}
                allProductsLoaded={allProductsLoaded}
              ></ProductList>
            )}
            {value === 1 && <DetailsTab mobile={mobile} store={storeData.current}></DetailsTab>}
            {value === 2 && (
              <DealsTab /*deals={dealList}*/ storeId={storeId} province={province} city={city}></DealsTab>
            )}
            {value === 3 && (
              <Fragment>
                {!preview.state.screen && (
                  <ReviewsSection
                    type="store"
                    itemId={storeId}
                    ownerName={storeData.current.name}
                    disableReviews={storeData.current.disableReviews}
                    // reviews={reviews}
                    // focusReviewForm={focusReviewForm}
                  />
                )}
                {preview.state.screen && <Typography>Not available in preview mode.</Typography>}
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default /*withRouter(*/ StoreDetailsView /*)*/;
