import axios from '../axios';
// import { setJWT, setUserID, removeJWTAndUserID } from "./cookies";
import { ROLES } from './constants';

const getAppData = async () => {
  try {
    return await axios.get('/appdata').then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const authenticate = async (email, password) => {
  try {
    return await axios
      .post('/authenticate', {
        username: email,
        password: password,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const login = async (email, password, setJWT, setUserID) => {
  try {
    return await axios
      .post('/authenticate', {
        username: email,
        password: password,
      })
      .then((res) => {
        // setJWT(res.data.token);
        // setUserID(res.data.userID);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
        return res;
      });
  } catch (error) {
    throw error;
  }
};

const sendReset = async (email) => {
  try {
    await axios
      .post('/users/requestResetEmail', {
        email: email,
      })
      .then((res) => {});
  } catch (error) {
    throw error;
  }
};

const sendConfirmation = async (email) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  try {
    await axios.post('/users/requestConfirmEmail', `"${email}"`, config).then((res) => {});
  } catch (error) {
    throw error;
  }
};

const changePassword = async (password, token) => {
  try {
    await axios
      .post('/users/changePassword', {
        password: password,
        token: token,
      })
      .then((res) => {});
  } catch (error) {
    throw error;
  }
};

const confirmEmail = async (token) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  try {
    await axios.post('/users/confirmEmail', `"${token}"`, config).then((res) => {});
  } catch (error) {
    throw error;
  }
};

const getRoles = async () => {
  try {
    return await axios.get(`/roles`).then((res) => res.data._embedded.roles);
  } catch (error) {
    throw error;
  }
};

const getUsers = async () => {
  try {
    return await axios.get(`/users?size=1000`).then((res) => res.data._embedded.users);
  } catch (error) {
    throw error;
  }
};

const getUsersWithParams = async (page, size) => {
  try {
    return await axios.get(`/users?page=${page}&size=${size}&sort=createDateTime,desc`).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

// ao: search user by username
const getUserByUsername = async (username, size = 50) => {
  try {
    return await axios.get(`/users/search/multiParam?username=${username}&size=${size}`).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const getUser = async (userID, headers) => {
  if (headers) {
    try {
      return await axios.get(`/users/${userID}`, { headers }).then((res) => res.data);
    } catch (error) {
      throw error;
    }
  } else {
    try {
      return await axios.get(`/users/${userID}`).then((res) => res.data);
    } catch (error) {
      throw error;
    }
  }
};

const createUser = async (data, createRole) => {
  try {
    return await getRoles()
      .then(async (res) => {
        const selectedRole =
          res.find((role) => role.name === createRole) && res.find((role) => role.name === createRole)._links.self.href;

        return axios
          .post('/users', { ...data, role: selectedRole })
          .then((res) => res)
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

const updateUser = async (id, data) => {
  try {
    return await axios.patch(`/users/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const promoteUser = async (id, type) => {
  try {
    return await getRoles()
      .then(async (res) => {
        const storeRole =
          res.find((role) => role.name === ROLES.ROLE_STORE_ADMIN) &&
          res.find((role) => role.name === ROLES.ROLE_STORE_ADMIN)._links.self.href;

        const brandRole =
          res.find((role) => role.name === ROLES.ROLE_BRAND_ADMIN) &&
          res.find((role) => role.name === ROLES.ROLE_BRAND_ADMIN)._links.self.href;

        const role = type === 'store' ? storeRole : type === 'brand' ? brandRole : '';

        const data = role ? { role: role } : {};

        return axios
          .patch(`/users/${id}`, data)
          .then((res) => res)
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (id) => {
  try {
    return await axios.delete(`/users/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getDeliveryPageData = async (id) => {
  const url = `/locations/${id}?projection=deliveryTab`;

  try {
    return await axios.get(url).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getStores = async (type, locId, published, nationwide, fetchSize = 1000, page = null) => {
  if (locId && !isNaN(locId)) {
    locId = `${axios.defaults.baseURL}/locations/${locId}`;
  }
  let fetchURL = `/stores/search/multiParam?`;
  if (type) fetchURL += `&storeType=${type}`;
  if (locId) fetchURL += `&location=${locId}`;
  if (published) fetchURL += `&published=${published}`;
  if (nationwide) fetchURL += `&nationwide=${nationwide}`;
  if (page) fetchURL += `&page=${page}`;
  if (fetchSize) fetchURL += `&size=${fetchSize}`;
  try {
    return await axios.get(fetchURL).then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrands = async (published) => {
  const url = `/brands/search/multiParam?${published ? `published=${published}` : ``}&size=1000`;

  try {
    return await axios.get(url).then((res) => res.data._embedded.brands);
  } catch (error) {
    throw error.response.data;
  }
};

const getStoresForAdmin = async () => {
  try {
    return await axios.get(`/stores?projection&size=1000`).then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getNationWideDeliveries = async () => {
  const url = '/stores/search/multiParam?published=true&size=1000&nationwide=true';
  try {
    return await axios.get(url).then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};
const getStoreSummaryByType = async (type, page, size, assignCnxCallBack) => {
  const url = `/stores/search/multiParam?storeType=${type}&projection=enhancedSummary&sort=createDateTime,desc&page=${page}&size=${size}`;

  try {
    return await axios
      .get(url, {
        cancelToken: new axios.CancelToken((c) => {
          if (assignCnxCallBack) assignCnxCallBack(c);
        }),
      })
      .then((res) => res.data);
  } catch (error) {
    if (axios.isCancel(error)) return;
    throw error.response.data;
  }
};
const getEnhancedStoresForAdmin = async (page, size, assignCnxCallBack) => {
  const url = `/stores?projection=enhancedSummary&sort=createDateTime,desc&page=${page}&size=${size}`;
  try {
    return await axios
      .get(url, {
        cancelToken: new axios.CancelToken((c) => {
          if (assignCnxCallBack) assignCnxCallBack(c);
        }),
      })
      .then((res) => res.data);
  } catch (error) {
    if (axios.isCancel(error) || error === undefined) return;
    throw error.response.data;
  }
};

const getStoresForUser = async (id) => {
  try {
    return await axios
      .get(`/users/${id}/stores?projection&size=1000&sort=createDateTime,desc`)
      .then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getEnhancedStoresForUser = async (id) => {
  try {
    return await axios
      .get(`/users/${id}/stores?projection=enhancedSummary&size=1000&sort=createDateTime,desc`)
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrandsForAdmin = async () => {
  try {
    return await axios
      .get(`/brands?projection&size=1000&sort=createDateTime,desc`)
      .then((res) => res.data._embedded.brands);
  } catch (error) {
    throw error.response.data;
  }
};

const getEnhancedBrandsForAdmin = async (page, size) => {
  try {
    return await axios
      .get(`/brands?projection=enhancedSummary&sort=createDateTime,desc&page=${page}&size=${size}`)
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrandsForUser = async (id) => {
  try {
    return await axios.get(`/users/${id}/brands?projection&size=1000`).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getEnhancedBrandsForUser = async (id) => {
  try {
    return await axios.get(`/users/${id}/brands?projection=enhancedSummary&size=1000`).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getStore = async (id, projection) => {
  let url = `/stores/${id}`;
  if (projection) url += `?projection=${projection}`;
  try {
    return await axios.get(url).then((res) => res);
  } catch (error) {
    throw error.response?.data ?? error;
  }
};

// ao: search store by name
const getStoreByName = async (name, size = 50) => {
  try {
    return await axios
      .get(`/stores/search/searchByName?projection=enhancedSummary&name=${name}&size=${size}`)
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

// ao: sdaw-1044 new api calls for global search - Stores, Brands and Products.
const getGlobalStoresByName = async (name, published, size = 50) => {
  try {
    return await axios
      .get(`/stores/search/searchByName?name=${name}&size=${size}&published=${published}`)
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getGlobalBrandsByName = async (name, published, size = 50) => {
  try {
    return await axios
      .get(`/brands/search/multiParam?name=${name}&size=${size}&published=${published}`)
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getGlobalProductsByName = async (name, removeFromMenu, detailed, size = 50) => {
  let url = `/products/search/multiParam?name=${name}&size=${size}&removeFromMenu=${removeFromMenu}`;
  if (detailed) url += `&projection=detailed`;
  try {
    return await axios.get(url).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrand = async (id, projection) => {
  let url = `/brands/${id}`;
  if (projection) url += `?projection=${projection}`;
  try {
    return await axios.get(url).then((res) => res);
  } catch (error) {
    throw error.response?.data ?? error;
  }
};

const getStoreDetailedProjection = async (id) => {
  try {
    return await axios.get(`/stores/${id}?projection=detailed`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrandDetailedProjection = async (id) => {
  try {
    return await axios.get(`/brands/${id}?projection=detailed`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createStore = async (data) => {
  data.owner = `${axios.defaults.baseURL}${data.owner}`;
  try {
    return await axios.post('/stores', data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const cloneStore = async (data) => {
  const base = `${axios.defaults.baseURL}/stores/copy?store=` + data;
  try {
    return await axios.post(base).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateStore = async (id, data) => {
  try {
    return await axios.patch(`/stores/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteStore = async (id) => {
  try {
    return await axios.delete(`/stores/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const createBrand = async (data) => {
  data.owner = `${axios.defaults.baseURL}${data.owner}`;
  try {
    return await axios.post('/brands', data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateBrand = async (id, data) => {
  try {
    return await axios.patch(`/brands/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteBrand = async (id) => {
  try {
    return await axios.delete(`/brands/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getProductsForStore = async (id) => {
  try {
    return await axios.get(`/stores/${id}/products?projection`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const getProductsForBrand = async (id) => {
  try {
    return await axios.get(`/brands/${id}/products?projection`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const getProductsForStoreDetailed = async (id) => {
  try {
    return await axios.get(`/stores/${id}/products?projection=detailed`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const getProductsForStoreCustomized = async (id) => {
  /* This request gets ALL products and cannot be pagenated, with High # of products (estimated 800) the request not rtn due to timeout
  use searchProductsForStore with pagenation to improve fetch speed
  
  Search products by store allows pagination
  */
  try {
    return await axios.get(`/stores/${id}/products?projection=customized`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const searchProductsForStore = async (
  storeLinkID,
  projection,
  publishedOnly = true,
  fetchSize = 1000,
  page = null,
  sortBy = 'name',
  assignCnxCallBack
) => {
  /* Will replace  getProductsForStoreCustomized, becuase of options that can be used to improve fetch speed*/
  // sortBy is needed in order ot guarantee pagination will work properly without sending duplicate records.
  const checkAndBuildID = (inputID) => {
    //StoreLink requires full URL: https://${api}.s7daw.com:8080/api/v1/stores/2655
    //If ID just #, build the appropriate URL and Rtn it (uses !isNaN b/c inputID will always be a string)
    if (!isNaN(inputID)) return `${axios.defaults.baseURL}/${inputID}`;
    return inputID;
  };
  let fetchURL = `/products/search/byStore?store=${checkAndBuildID(storeLinkID)}`;
  //Add parameter Options
  if (projection) fetchURL += `&projection=${projection}`;
  if (fetchSize) fetchURL += `&size=${fetchSize}`;
  if (page !== null) fetchURL += `&page=${page}`;
  if (publishedOnly) fetchURL += `&removeFromMenu=${false}`;
  if (sortBy) fetchURL += `&sort=${sortBy}`;

  try {
    return await axios
      .get(fetchURL, {
        cancelToken: new axios.CancelToken((c) => {
          if (assignCnxCallBack) assignCnxCallBack(c);
        }),
      })
      .then((res) => res.data); //Must rtn .data for pagination.
  } catch (e) {
    if (e === undefined) return;
    const error = e.response?.data;
    throw error;
  }
};

const getProductsForBrandDetailed = async (id) => {
  try {
    return await axios.get(`/brands/${id}/products?projection=detailed`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const getProductsForBrandCustomized = async (id) => {
  try {
    return await axios.get(`/brands/${id}/products?projection=customized`).then((res) => res.data._embedded.products);
  } catch (error) {
    throw error.response.data;
  }
};

const getProduct = async (id) => {
  try {
    return await axios.get(`/products/${id}`).then((res) => res);
  } catch (error) {
    throw error.response?.data ?? error;
  }
};

const getProductDetailedProjection = async (id) => {
  try {
    return await axios.get(`/products/${id}?projection=detailed`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createProduct = async (id, data, brand) => {
  let body = {};

  if (!brand) {
    body = {
      ...data,
      stores: [`${axios.defaults.baseURL}/stores/${id}`],
    };
  } else {
    body = {
      ...data,
      brand: `${axios.defaults.baseURL}/brands/${id}`,
    };
  }

  try {
    return await axios.post(`/products`, body).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateProduct = async (id, data) => {
  try {
    return await axios.patch(`/products/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const addStoreToProduct = async (id, stores) => {
  const storeIds = stores.map((id) => `${axios.defaults.baseURL}/stores/${id}`);

  try {
    return await axios.patch(`/products/${id}`, { stores: storeIds }).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const createProductCustomization = async (storeId, productId, data) => {
  const store = `${axios.defaults.baseURL}/stores/${storeId}`;
  const product = `${axios.defaults.baseURL}/products/${productId}`;

  try {
    return await axios
      .post(`/productCustomizations`, {
        store,
        product,
        productPricingList: data.productPricingList,
        removeFromMenu: data.removeFromMenu,
        flags: data.flags,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateProductCustomization = async (id, data) => {
  try {
    return await axios.patch(`/productCustomizations/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteProductCustomization = async (id) => {
  try {
    return await axios.delete(`/productCustomizations/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getProductCustomized = async (id) => {
  try {
    return await axios.get(`/products/${id}?projection=customized`).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const deleteProduct = async (id) => {
  try {
    return await axios.delete(`/products/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const bulkDeleteProducts = async (data) => {
  try {
    return await axios
      .delete(`/products/bulkDelete`, {
        data,
        headers: { 'Content-Type': 'text/uri-list' },
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const removeProductsFromMenu = async (data) => {
  try {
    return await axios
      .patch(`/products/setRemoveFromMenu`, {
        products: data,
        removeFromMenu: true,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const removeCustomizationsFromMenu = async (data) => {
  try {
    return await axios
      .patch(`/productCustomizations/setRemoveFromMenu`, {
        productCustomizations: data,
        removeFromMenu: true,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const addProductsToMenu = async (data) => {
  try {
    return await axios
      .patch(`/products/setRemoveFromMenu`, {
        products: data,
        removeFromMenu: false,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const addCustomizationsToMenu = async (data) => {
  try {
    return await axios
      .patch(`/productCustomizations/setRemoveFromMenu`, {
        productCustomizations: data,
        removeFromMenu: false,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getDeals = async () => {
  try {
    return await axios.get(`/deals?projection&size=1000`).then((res) => res.data._embedded.deals);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealsByLocation = async (id, published) => {
  //Ensure id is sent in the URI format
  if (!isNaN(id)) {
    id = `${axios.defaults.baseURL}/locations/${id}`;
  }

  try {
    return await axios
      .get(
        `/deals/search/multiParam?${id ? `location=${id}` : ``}${
          published ? `${id ? `&` : ``}published=${published}` : ``
        }&size=1000`
      )
      .then((res) => res.data._embedded.deals);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealsForStore = async (id) => {
  try {
    return await axios.get(`/stores/${id}/deals?projection`).then((res) => res.data._embedded.deals);
  } catch (error) {
    throw error.response.data;
  }
};

const getDeal = async (id) => {
  try {
    return await axios.get(`/deals/${id}`).then((res) => res);
  } catch (error) {
    throw error.response?.data ?? error;
  }
};

const createDeal = async (id, data) => {
  const body = {
    ...data,
    store: `${axios.defaults.baseURL}/stores/${id}`,
  };

  try {
    return await axios.post(`/deals`, body).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateDeal = async (id, data) => {
  try {
    return await axios.patch(`/deals/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteDeal = async (id) => {
  try {
    return await axios.delete(`/deals/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const uploadImage = async (data) => {
  try {
    return await axios.post(`/files`, data).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const updateImage = async (id, data) => {
  try {
    return await axios.patch(`/files/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteImage = async (id) => {
  try {
    return await axios.delete(`/files/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getLocationsMobile = async (published) => {
  try {
    return await axios
      .get(`/locations/search/multiParam?hasPublishedStores=${published}&size=1000`)
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getGoogleGeocodingApi = async (address) => {
  try {
    return await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${'AIzaSyAt3-6rmNkE-rHajNz7yJluDV0UI19uLOU'}`
      )
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getLocations = async () => {
  try {
    return await axios.get(`/locations/search/findAllLocationsWithPublishedStore`).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getLocationBySearch = async (province, city, published) => {
  try {
    return await axios
      .get(
        `/locations/search/multiParam?province=${province}${city ? `&city=${city}` : ``}${
          published ? `&hasPublishedStores=${published}` : ``
        }&size=1000`
      )
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createLocation = async (province, city) => {
  const body = {
    province: province,
    city: city,
  };

  try {
    return await axios.post(`/locations`, body).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const getLandingProjection = async (id) => {
  try {
    return await axios.get(`/locations/${id}?projection=landing`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getStoreRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDeliveryRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getRecommendedRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getFeaturedStoresRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/featuredStoreRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getRecommendedStoresRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/recommendedRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getAllStoresRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/allStoreRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getFeaturedDeliveriesRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/featuredDeliveryRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getAllDeliveriesRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/allDeliveryRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getAllStoreRanksByLocation = async (id) => {
  try {
    return await axios.get(`/locations/${id}/allStoreRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealRanksByLocation = async (id) => {
  try {
    return await axios.get(`/locations/${id}/featuredDealRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getListByLocation = async (locID, endpoint, projection = null) => {
  if (!endpoint) throw new Error('Missing Endpoint');
  let fetchUrl = `/locations/${locID}/${endpoint}`;
  if (projection) fetchUrl += `?projection=${projection}`;
  try {
    return await axios.get(fetchUrl).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const updateType = (type) => {
  if (type.includes('pop')) {
    return 'Recommended';
  }
  return type;
};

const createRanks = async (type, data, location) => {
  if (!data.length) return;
  let url = '';
  type = updateType(type);
  switch (type) {
    case 'Storefront':
    case 'Clinic':
      url = '/featuredStoreRanks';
      break;

    case 'StorefrontOrdering':
    case 'ClinicOrdering':
      url = '/allStoreRanks';
      break;

    case 'Delivery':
      url = '/featuredDeliveryRanks';
      break;

    case 'DeliveryOrdering':
      url = '/allDeliveryRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    case 'Recommended':
      url = '/recommendedRanks';
      break;

    default:
      break;
  }

  const posts = data.map((entity) =>
    axios.post(url, {
      rank: entity.rank,
      store:
        type === 'Storefront' ||
        type === 'StorefrontOrdering' ||
        type === 'Recommended' ||
        type === 'Clinic' ||
        type === 'ClinicOrdering'
          ? entity.store._links.self.href.replace('{?projection}', '')
          : '',
      delivery:
        type === 'Delivery' || type === 'DeliveryOrdering'
          ? entity.delivery._links.self.href.replace('{?projection}', '')
          : '',
      deal:
        type === 'FeaturedDeal' || type === 'RecommendedDeal'
          ? entity.deal._links.self.href.replace('{?projection}', '')
          : '',
      location: location,
    })
  );

  try {
    return await Promise.all(posts);
  } catch (error) {
    throw error;
  }
};

const updateRanks = async (type, data) => {
  if (!data.length) return;

  let url = '';
  type = updateType(type);

  switch (type) {
    case 'Storefront':
    case 'Clinic':
      url = '/featuredStoreRanks';
      break;

    case 'StorefrontOrdering':
    case 'ClinicOrdering':
      url = '/allStoreRanks';
      break;

    case 'Delivery':
      url = '/featuredDeliveryRanks';
      break;

    case 'DeliveryOrdering':
      url = '/allDeliveryRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    case 'Recommended':
      url = '/recommendedRanks';
      break;

    default:
      break;
  }

  const patches = data.map((rank) =>
    axios.patch(`${url}/${rank.id}`, {
      rank: rank.rank,
    })
  );

  try {
    return await Promise.all(patches);
  } catch (error) {
    throw error;
  }
};

const deleteRanks = async (type, ids) => {
  if (!ids.length) return;
  let url = '';
  type = updateType(type);

  switch (type) {
    case 'Storefront':
    case 'Clinic':
      url = '/featuredStoreRanks';
      break;

    case 'StorefrontOrdering':
    case 'ClinicOrdering':
      url = '/allStoreRanks';
      break;

    case 'Delivery':
      url = '/featuredDeliveryRanks';
      break;

    case 'DeliveryOrdering':
      url = '/allDeliveryRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    case 'Recommended':
      url = '/recommendedRanks';
      break;

    default:
      break;
  }

  const deletes = ids.map((id) => axios.delete(`${url}/${id}`));

  try {
    return await Promise.all(deletes);
  } catch (error) {
    throw error;
  }
};

const getFeaturedProducts = async () => {
  try {
    return await axios.get(`/featuredBrandRanks`).then((res) => res.data._embedded.featuredBrandRanks);
  } catch (error) {
    throw error.response.data;
  }
};

const createFeaturedProduct = async (data) => {
  try {
    return await axios.post(`/featuredBrandRanks`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateFeaturedProduct = async (id, data) => {
  try {
    return await axios.patch(`/featuredBrandRanks/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteFeaturedProduct = async (id) => {
  try {
    return await axios.delete(`/featuredBrandRanks/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getStoreReviews = async (id) => {
  try {
    return await axios.get(`/stores/${id}/reviews`).then((res) => res.data._embedded.reviews);
  } catch (error) {
    throw error.response.data;
  }
};

const getBrandReviews = async (id) => {
  try {
    return await axios.get(`/brands/${id}/reviews`).then((res) => res.data._embedded.reviews);
  } catch (error) {
    throw error.response.data;
  }
};

const getProductReviews = async (id) => {
  try {
    return await axios.get(`/products/${id}/reviews`).then((res) => res.data._embedded.reviews);
  } catch (error) {
    throw error.response.data;
  }
};

const createReview = async (data, type, itemId, userId) => {
  data = {
    ...data,
    store: type === 'store' ? `${axios.defaults.baseURL}/stores/${itemId}` : null,
    product: type === 'product' ? `${axios.defaults.baseURL}/products/${itemId}` : null,
    brand: type === 'brand' ? `${axios.defaults.baseURL}/brands/${itemId}` : null,
    user: `${axios.defaults.baseURL}/users/${userId}`,
  };

  try {
    return await axios.post(`/reviews`, data).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const updateReview = async (id, data) => {
  try {
    return await axios.patch(`/reviews/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteReview = async (id) => {
  try {
    return await axios.delete(`/reviews/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getBanners = async () => {
  try {
    return await axios.get(`/banners`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const saveBanner = async (bannerId, store) => {
  try {
    return await axios
      .patch(`/banners/${bannerId}`, {
        store: store ? store._links.self.href : '',
      })
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createAdminMessage = async (title, body, stores, brands) => {
  try {
    return await axios
      .post(`/adminMessages`, {
        title,
        body,
        unreadBrands: brands,
        unreadStores: stores,
      })
      .then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const markStoreMessageRead = async (messageId, storeIdLink) => {
  try {
    return await axios
      .delete(`/adminMessages/${messageId}/markStoresRead`, {
        data: storeIdLink,
        headers: { 'Content-Type': 'text/uri-list' },
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const markBrandMessageRead = async (id, data) => {
  try {
    return await axios
      .delete(`/adminMessages/${id}/markBrandsRead`, {
        data,
        headers: { 'Content-Type': 'text/uri-list' },
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const setPushNotificationDetails = async (id, token, platform) => {
  try {
    return await axios
      .patch(`/users/${id}`, {
        pushDeviceToken: token,
        mobilePushPlatform: platform,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateUserNotifications = async (id, location1, location2, mailoutSubscribed, followedStores) => {
  try {
    return await axios
      .patch(`/users/${id}`, {
        primaryCity: location1,
        secondaryCity: location2,
        mailoutSubscribed,
        followedStores,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const createPinAddress = async (city, province, street, store, postalCode) => {
  try {
    return await axios
      .post(`/pinAddresses`, {
        address: {
          city,
          province,
          street,
          postalCode,
        },
        store,
      })
      .then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const patchPinAddress = async (id, street, postalCode, coords) => {
  const { longitude, latitude } = coords;
  try {
    return await axios
      .patch(`/pinAddresses/${id}`, {
        address: {
          street,
          postalCode,
        },
        longitude,
        latitude,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deletePinAddress = async (id) => {
  try {
    return await axios.delete(`/pinAddresses/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getAllFollowersOfStore = async (id) => {
  try {
    return await axios.get(`/stores/${id}/followers`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getAllAdminMessages = async () => {
  try {
    return await axios.get(`/adminMessages?size=1000`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getMessagesByStore = async (storeID, msgTypeToFetch) => {
  if (msgTypeToFetch !== 'unread' && msgTypeToFetch !== 'read') throw new Error('Invalid message type');
  const MESSAGE_ENDPOINTS = {
    unread: 'adminUnreadMessages',
    read: 'adminReadMessages',
  };

  try {
    return await axios.get(`/stores/${storeID}/${MESSAGE_ENDPOINTS[msgTypeToFetch]}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getAdminMessage = async (id) => {
  try {
    return await axios.get(`/adminMessages/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const deleteAdminMessage = async (id) => {
  try {
    return await axios.delete(`/adminMessages/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealsWithApprovals = async (id) => {
  if (!id) {
    try {
      return await axios
        .get(`/deals?published=true&projection=approvalSummary&size=1000`)
        .then((res) => res.data._embedded.deals);
    } catch (error) {
      throw error.response.data;
    }
  } else {
    try {
      return await axios
        .get(`/deals/search/multiParam?location=${id}&published=true&projection=approvalSummary&size=1000`)
        .then((res) => res.data._embedded.deals);
    } catch (error) {
      throw error.response.data;
    }
  }
};

const getAllBookingsByRange = async (dateRange, statusFilter, fetchSize = 500) => {
  const { startWeek, endWeek, startYear, endYear } = dateRange;
  let fetchURL = `/bookings/search/multiParam?startWeek=${startWeek}&startYear=${startYear}&endWeek=${endWeek}&endYear=${endYear}&projection=admin`;
  fetchURL = fetchURL + `&size=${fetchSize}`;
  if (statusFilter === 'approved' || statusFilter === 'pending') {
    fetchURL = fetchURL + `&status=${statusFilter}`;
  }
  try {
    return await axios.get(fetchURL).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getBookingsByRangeLite = async (dateRange) => {
  const { startWeek, endWeek, startYear, endYear } = dateRange;
  const fetchURL = `/bookings/search/multiParam?startWeek=${startWeek}&startYear=${startYear}&endWeek=${endWeek}&endYear=${endYear}&size=200`;
  try {
    return await axios.get(fetchURL).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getApprovedBookingsExtended = async (dateRange) => {
  const { startWeek, endWeek, startYear, endYear } = dateRange;
  const fetchURL = `/bookings/search/multiParam?startWeek=${startWeek}&startYear=${startYear}&endWeek=${endWeek}&endYear=${endYear}&status=approved&projection=extended&size=200`;
  try {
    return await axios.get(fetchURL).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getApprovedBookingsExtendedAllStatus = async (dateRange) => {
  const { startWeek, endWeek, startYear, endYear } = dateRange;
  const fetchURL = `/bookings/search/multiParam?startWeek=${startWeek}&startYear=${startYear}&endWeek=${endWeek}&endYear=${endYear}&projection=extended&size=200`;
  try {
    return await axios.get(fetchURL).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getTopBanners = async () => {
  try {
    return await axios.get(`/topBanners`).then((res) => res);
  } catch (error) {
    throw error.response?.data ?? error;
  }
};

const createBooking = async (body) => {
  try {
    return await axios.post('/bookings', body).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const updateBooking = async (id, body) => {
  try {
    return await axios.patch(`/bookings/${id}`, body).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const cancelBooking = async (bookingID) => {
  //Changes status to cancelled for audit purposes.
  try {
    return await axios
      .patch(`/bookings/${bookingID}`, { displayRank: null, status: 'cancelled' })
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const getLocationInfo = async (locationID) => {
  try {
    return await axios.get(`/locations/${locationID}`).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const createTopBanner = async (data) => {
  try {
    return await axios.post('/topBanners', data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const saveTopBanner = async (id, data) => {
  try {
    return await axios.patch(`/topBanners/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteTopBanner = async (id) => {
  try {
    return await axios.delete(`/topBanners/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getEntityOwner = async (entityID, isBrand) => {
  let fetchURL = `/${isBrand ? 'brands' : 'stores'}/${entityID}/owner`;
  try {
    return await axios.get(fetchURL).then((res) => res);
  } catch (error) {
    throw error;
  }
};
const fetchHref = async (href, dataKey) => {
  if (!href || !dataKey) throw new Error('Missing href or Datakey');
  let strippedURL = href.split('/').slice(5).join('/').replace('{?projection}', '');
  let fetchURL = `/${strippedURL}`;
  try {
    return await axios.get(fetchURL).then((res) => {
      return res.data?._embedded?.[dataKey] || res.data;
    });
  } catch (error) {
    throw error;
  }
};

const deleteHref = async (href) => {
  if (!href) throw new Error('Missing href');
  try {
    return await axios.delete(href).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const requestDeleteAccountEmail = async (email) => {
  try {
    return await axios.post(`/users/requestDeleteAccount`, { email }).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const disableUser = async (userId) => {
  //Changes enabled field to false
  try {
    return await axios.patch(`/users/${userId}`, { enabled: false }).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const unpublishAsset = async (assetIDLink) => {
  try {
    return await axios.patch(`${assetIDLink}`, { published: false }).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

export default {
  authenticate,
  login,
  sendReset,
  sendConfirmation,
  changePassword,
  confirmEmail,
  getRoles,
  getUsers,
  getUser,
  getUsersWithParams,
  createUser,
  updateUser,
  promoteUser,
  deleteUser,
  getAppData,
  getGoogleGeocodingApi,
  getStores,
  getNationWideDeliveries,
  getBrands,
  getStoresForAdmin,
  getStoreSummaryByType,
  getEnhancedStoresForAdmin,
  getStoresForUser,
  getEnhancedStoresForUser,
  getBrandsForAdmin,
  getEnhancedBrandsForAdmin,
  getBrandsForUser,
  getEnhancedBrandsForUser,
  getStore,
  getBrand,
  getStoreDetailedProjection,
  getBrandDetailedProjection,
  cloneStore,
  createStore,
  updateStore,
  deleteStore,
  createBrand,
  updateBrand,
  deleteBrand,
  getDeals,
  getDealsByLocation,
  getDealsForStore,
  getDeal,
  getDealsWithApprovals,
  getDeliveryPageData,
  createDeal,
  updateDeal,
  deleteDeal,
  getProductsForStore,
  searchProductsForStore,
  getProductsForBrand,
  getProductsForStoreDetailed,
  getProductsForStoreCustomized,
  getProductsForBrandDetailed,
  getProductsForBrandCustomized,
  getProduct,
  getProductDetailedProjection,
  createProduct,
  updateProduct,
  addStoreToProduct,
  createProductCustomization,
  updateProductCustomization,
  deleteProductCustomization,
  getProductCustomized,
  deleteProduct,
  bulkDeleteProducts,
  removeProductsFromMenu,
  removeCustomizationsFromMenu,
  addProductsToMenu,
  addCustomizationsToMenu,
  uploadImage,
  updateImage,
  deleteImage,
  getLocations,
  getLocationsMobile,
  getLocationBySearch,
  createLocation,
  getRanks,
  getLandingProjection,
  getStoreRanks,
  getDeliveryRanks,
  getAllStoreRanksByLocation,
  getDealRanksByLocation,
  getListByLocation,
  getDealRanks,
  getRecommendedRanks,
  getFeaturedStoresRanks,
  getAllStoresRanks,
  getFeaturedDeliveriesRanks,
  getAllDeliveriesRanks,
  getRecommendedStoresRanks,
  getAllFollowersOfStore,
  createRanks,
  updateRanks,
  deleteRanks,
  getFeaturedProducts,
  createFeaturedProduct,
  updateFeaturedProduct,
  deleteFeaturedProduct,
  getStoreReviews,
  getBrandReviews,
  getProductReviews,
  createReview,
  updateReview,
  deleteReview,
  getBanners,
  saveBanner,
  createAdminMessage,
  markStoreMessageRead,
  markBrandMessageRead,
  setPushNotificationDetails,
  updateUserNotifications,
  getAllAdminMessages,
  getMessagesByStore,
  getAdminMessage,
  deleteAdminMessage,
  getStoreByName,
  patchPinAddress,
  createPinAddress,
  deletePinAddress,
  getUserByUsername,
  getGlobalProductsByName,
  getGlobalBrandsByName,
  getGlobalStoresByName,
  createBooking,
  updateBooking,
  cancelBooking,
  getAllBookingsByRange,
  getBookingsByRangeLite,
  getApprovedBookingsExtended,
  getApprovedBookingsExtendedAllStatus,
  getTopBanners,
  createTopBanner,
  saveTopBanner,
  deleteTopBanner,
  getLocationInfo,
  getEntityOwner,
  fetchHref,
  deleteHref,
  requestDeleteAccountEmail,
  disableUser,
  unpublishAsset,
};
