import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';

export default function ProductDetailsSection({ classes, product }) {
  return (
    <div className={classes.productDetails}>
      {/* ================================== GENETICS AND TIME OF USE ================================= */}
      <div className={classes.strainDetails}>
        {/* =========== STRAIN CONTENT > img and text */}
        <div className={classes.strainContent}>
          <div className={classes.imageSection}>
            {product.genetics && (
              <Tooltip title={product.genetics} placement="left">
                <img
                  src={require(`../../assets/genetic-types/${product.genetics.toLowerCase().replace(/ /g, '')}.svg`)}
                  alt={`strain-${product.genetics}`}
                  className={classes.symbol}
                />
              </Tooltip>
            )}
          </div>
          <div className={classes.infoSection}>
            {product.genetics && <Typography variant="caption">{product.genetics}</Typography>}
            {product.thc > 0 && product.thc && <Typography variant="caption">THC: {product.thc}%</Typography>}
            {product.cbd > 0 && product.cbd && <Typography variant="caption">CBD: {product.cbd}%</Typography>}
          </div>
        </div>

        {/* ================================== TIME OF USE > img and text */}
        {product.timeOfUse && (
          <div className={classes.timeOfUse}>
            <div className={classes.imageSection}>
              <Tooltip title={product.timeOfUse} placement="left">
                <img
                  src={require(`../../assets/time-of-use/${product.timeOfUse.toLowerCase().replace(/ /g, '')}.svg`)}
                  alt={`strain-${product.timeOfUse}`}
                  className={classes.symbol}
                />
              </Tooltip>
            </div>
            <div className={classes.infoSection}>
              {product.timeOfUse && (
                <Fragment>
                  <Typography variant="caption">Time of Use:</Typography>
                  <Typography variant="caption">{product.timeOfUse}</Typography>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {/* ================================== EFFECT AND USES > HIDDEN ON MOBILE ================================= */}
      <div className={classes.effectsAndUses}>
        <div className={classes.effects}>
          {product.effects.length > 0 && (
            <Fragment>
              <Typography variant="caption">Typical Effects</Typography>
              <div className={classes.symbolList}>
                {product.effects &&
                  product.effects.map((effect, index) => (
                    <Tooltip title={effect} arrow key={index}>
                      <img
                        src={require(`../../assets/effects/${effect.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`effect-${effect}`}
                        className={classes.symbol}
                      ></img>
                    </Tooltip>
                  ))}
                {!product.effects && <Typography variant="caption">N/A</Typography>}
              </div>
            </Fragment>
          )}
        </div>
        <div className={classes.uses}>
          {product.uses.length > 0 && (
            <Fragment>
              <Typography variant="caption">Common Uses</Typography>
              <div className={classes.symbolList}>
                {product.uses &&
                  product.uses.map((use, index) => (
                    <Tooltip title={use} arrow key={index}>
                      <img
                        src={require(`../../assets/uses/${use.toLowerCase().replace(/ /g, '')}.svg`)}
                        alt={`use-${use}`}
                        className={classes.symbol}
                      ></img>
                    </Tooltip>
                  ))}
                {!product.uses && <Typography variant="caption">N/A</Typography>}
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {/* ====================================== MOBILE > INFOSECT > GENETICS AND TIME OF USE ===================================== */}
      {/* <div className={classes.strainContentMobile}>
        <div className={classes.infoSection}>
          {product.genetics && <Typography className={classes.mobileInfoSectionText}>{product.genetics}</Typography>}
          {product.thc > 0 && product.thc && (
            <Typography className={classes.mobileInfoSectionText}>THC: {product.thc}%</Typography>
          )}
          {product.cbd > 0 && product.cbd && (
            <Typography className={classes.mobileInfoSectionText}>CBD: {product.cbd}%</Typography>
          )}
        </div>
      </div> */}
    </div>
  );
}
