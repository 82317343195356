import React from 'react';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const MAX_NAME_LENGTH = 36;
const MAX_PRICES_TO_SHOW = 4;

export default function NameAndPrice({ classes, product, priceList }) {
  return (
    <div className={classes.nameAndPrice}>
      <div className={classes.nameAndStrain}>
        <Typography className={classes.name}>
          {/* {product.name} */}
          {product.name.slice(0, MAX_NAME_LENGTH)}
          {product.name.length > MAX_NAME_LENGTH && '...'}
        </Typography>
      </div>
      {priceList && (
        <Table size="small" aria-label="pricing table">
          <TableHead>
            <TableRow variant="body2">
              {priceList.slice(0, MAX_PRICES_TO_SHOW).map((product, index) => (
                <TableCell className={classes.tableCellHead} key={index} component="th">
                  {product.quantity ? product.quantity : 'Each'}
                </TableCell>
              ))}
              {priceList.length > MAX_PRICES_TO_SHOW && (
                <TableCell className={classes.tableCellHead} component="th">
                  {''}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {priceList.slice(0, MAX_PRICES_TO_SHOW).map((product, index) => (
                <TableCell className={classes.tableCell} key={index}>
                  <strong>
                    ${product.price === Math.floor(product.price) ? product.price : product.price?.toFixed(2)}
                  </strong>
                </TableCell>
              ))}
              {priceList.length > MAX_PRICES_TO_SHOW && (
                <TableCell className={classes.moreCell}>
                  <strong>+ More</strong>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
}
